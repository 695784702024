<div class="modal-backdrop show" @fadeIn></div>
<div class="modal d-block" tabindex="-1" role="dialog">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h2 class="modal-title">Edit Account</h2>
        <button type="button" class="close" aria-label="Close" (click)="close()">
          <svg-icon src="assets/icons/material-design-icons/close/sharp.svg"></svg-icon>
        </button>
      </div>
      <div class="modal-body">
        <form [formGroup]="userForm" (ngSubmit)="onSubmit()">
          <div class="form-group">
            <label for="user-name">Your Name</label>
            <input
              type="text"
              class="form-control"
              id="user-name"
              placeholder="Enter your name"
              formControlName="name">
          </div>
          <div class="form-group">
            <label for="user-email">Your Email</label>
            <input
              type="email"
              class="form-control"
              id="user-email"
              placeholder="Enter your email"
              formControlName="email">
          </div>
          <div class="form-group iti-block">
            <label for="user-email">Your Mobile Number</label>
            <input
              #mobileNumber
              type="tel"
              class="form-control"
              id="mobile-number"
              placeholder="Enter your mobile number"
              formControlName="mobile_number"
              (countrychange)="onCountryChange()">
          </div>
          <div class="page-inset-actions">
            <button
              type="button"
              class="btn btn-outline-secondary"
              (click)="close()">
              Cancel
            </button>
            <ng-container *ngIf="loading$ | async | yesNo as loading">
              <button
                type="submit"
                class="btn btn-primary"
                [disabled]="!userForm.valid || loading === 'yes'">
                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" *ngIf="loading === 'yes'"></span>
                Update
              </button>
            </ng-container>
          </div>
        </form>
        <ng-container *ngIf="loaded$ | async">
          <div class="alert alert-success" role="alert" *ngIf="message$ | async as message" [innerHTML]="message"></div>
        </ng-container>
        <div class="alert alert-danger" role="alert" *ngIf="error$ | async as error" [innerHTML]="error.error?.message || 'Error'"></div>
      </div><!-- end .modal-body -->
    </div>
  </div>
</div>
