<div class="modal-backdrop show" @fadeIn></div>
<div class="modal d-block" tabindex="-1" role="dialog">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h2 class="modal-title mx-auto">Disclaimer</h2>
        <button class="close" aria-label="Close" (click)="close()">
          <svg-icon src="assets/icons/material-design-icons/close/sharp.svg"></svg-icon>
        </button>
      </div>
      <div class="modal-body">
        <ng-container *ngIf="account$ | async as account">
          <div class="pt-3">
            <div [innerHtml]="account.disclaimer_text"></div>
          </div>
        </ng-container>
      </div><!-- end .modal-body -->
    </div>
  </div>
</div>
