<div class="modal-backdrop show" @fadeIn></div>
<div class="modal d-block" tabindex="-1" role="dialog">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header p-0 justify-content-end">
        <button class="close m-0" aria-label="Close" (click)="close()">
          <svg-icon src="assets/icons/material-design-icons/close/sharp.svg"></svg-icon>
        </button>
      </div>
      <div class="modal-body text-center p-0">
        <iframe frameborder="0" border="0" cellspacing="0" [src]="iframeSrc"></iframe>
      </div><!-- end .modal-body -->
    </div>
  </div>
</div>
