<span class="account-logo">
  <ng-container *ngIf="account.main_nav_format === 'logo_only' || account.main_nav_format === 'channel_name_and_logo'">
    <!-- ng-container [ngSwitch]="account.uploaded_nav_logo_enabled">
        <img *ngSwitchCase="false" [src]="account.logo.url" [alt]="account.name">
        <img *ngSwitchCase="true" [src]="account.uploaded_nav_logo.url" [alt]="account.name">
    </ng-container -->
    <img [src]="imgSrc" [alt]="account.name">
  </ng-container>
  <ng-container *ngIf="account.main_nav_format === 'channel_name_only' || account.main_nav_format === 'channel_name_and_logo'">
    <span class="account-logo__name">{{ account.name }}</span>
  </ng-container>
</span>
