<div class="modal-backdrop show" @fadeIn></div>
<div class="modal d-block" tabindex="-1" role="dialog">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h2 class="modal-title">Change subscription plan</h2>
        <button type="button" class="close" aria-label="Close" (click)="close()">
          <svg-icon src="assets/icons/material-design-icons/close/sharp.svg"></svg-icon>
        </button>
      </div>
      <div class="modal-body">
        <ng-container *ngIf="user$ | async as user">
          <form [formGroup]="changePlanForm" (ngSubmit)="onSubmit()">
            <div class="form-group">
              <label for="plan">Subscription Plan</label>
              <select class="form-control" id="plan" formControlName="subscription_plan_id">
                <option value="">Select a new plan</option>
                <option
                  *ngFor="let plan of plans$ | async"
                  [value]="plan.id"
                  [disabled]="user.subscriptions[0]?.subscription_plan.id == plan.id">
                  {{ plan.label }}
                </option>
              </select>
            </div>
            <div class="d-flex justify-content-end mt-5">
              <button
                type="button"
                class="btn btn-outline-secondary"
                (click)="close()">
                Cancel
              </button>
              <ng-container *ngIf="loading$ | async | yesNo as loading">
                <button
                  type="submit"
                  class="btn btn-primary"
                  [disabled]="!changePlanForm.valid || loading === 'yes'">
                  <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" *ngIf="loading === 'yes'"></span>
                  {{ user.subscriptions[0]?.cancel_at_period_end ? 'Renew Plan' : 'Change Plan' }}
                </button>
              </ng-container>
            </div>
          </form>
        </ng-container>
        <div class="alert alert-success" role="alert" *ngIf="message$ | async as message" [innerHTML]="message"></div>
        <div class="alert alert-danger" role="alert" *ngIf="error$ | async as error" [innerHTML]="error.error?.message || 'Error'"></div>
      </div><!-- end .modal-body -->
    </div>
  </div>
</div>
