<div class="modal-backdrop show" @fadeIn></div>
<div class="modal d-block" tabindex="-1" role="dialog">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h2 class="modal-title">Update credit card</h2>
        <button class="close" aria-label="Close" (click)="close()">
          <svg-icon src="assets/icons/material-design-icons/close/sharp.svg"></svg-icon>
        </button>
      </div>
      <div class="modal-body">
        <p>Need to change the credit card information associated with your account? Update it here.</p>
        <form [formGroup]="changePaymentForm" (ngSubmit)="onSubmit()">
          <div class="form-group">
            <label>Credit or Debit Card</label>
            <div class="form-control" #cardContainer></div>
          </div>
          <div class="d-flex justify-content-end mt-5">
            <button
              type="button"
              class="btn btn-outline-secondary"
              (click)="close()">
              Cancel
            </button>
            <ng-container *ngIf="loading$ | async | yesNo as loading">
              <button
                type="submit"
                class="btn btn-primary"
                [disabled]="!changePaymentForm.valid || stripeLoading || loading === 'yes'">
                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" *ngIf="stripeLoading || loading === 'yes'"></span>
                Update
              </button>
            </ng-container>
          </div>
        </form>
        <div class="alert alert-danger" role="alert" *ngIf="stripeMessage" [innerHTML]="stripeMessage"></div>
        <div class="alert alert-success" role="alert" *ngIf="message$ | async as message" [innerHTML]="message"></div>
        <div class="alert alert-danger" role="alert" *ngIf="error$ | async as error" [innerHTML]="error.error?.message || 'Resend Email Error'"></div>
      </div><!-- end .modal-body -->
    </div>
  </div>
</div>
