<div class="modal-backdrop show" @fadeIn></div>
<div class="modal d-block" tabindex="-1" role="dialog">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title"></h5>
        <button type="button" class="close" aria-label="Close" (click)="close()">
          <svg-icon src="assets/icons/material-design-icons/close/sharp.svg"></svg-icon>
        </button>
      </div>
      <div class="modal-body">
        <section class="platforms-section platforms-section-tv">
          <h3 class="text-center">Watch On Your Television</h3>
          <div class="row">
            <ng-template [ngIf]="customText.appleStoreEnabled">
              <div class="col-auto platform platform-appletv">
                <div class="platform-inner">
                  <img src="assets/img/appletv.png" alt="Apple TV">
                  <p>2nd Gen+ | tvOS 10+</p>
                  <p *ngIf="(customText.tvosAppLive && customText.tvosStoreLink) as storeLink else tvosSoon">
                    <a [href]="storeLink" target="_blank">App Store</a>
                  </p>
                  <ng-template #tvosSoon>
                    <p class="coming-soon"><em>Coming Soon!</em></p>
                  </ng-template>
                </div>
              </div>
            </ng-template>

            <ng-template [ngIf]="customText.googlePlayEnabled">
              <div class="col-auto platform platform-androidtv">
                <div class="platform-inner">
                  <img src="assets/img/androidtv.png" alt="Android TV">
                  <p>Android 5.0+</p>
                  <p *ngIf="(customText.androidtvAppLive && customText.androidStoreLink) as storeLink else androidtvSoon">
                    <a [href]="storeLink" target="_blank">Google Play</a>
                  </p>
                  <ng-template #androidtvSoon>
                    <p class="coming-soon"><em>Coming Soon!</em></p>
                  </ng-template>
                </div>
              </div>
            </ng-template>

            <ng-template [ngIf]="customText.rokuEnabled">
              <div class="col-auto platform platform-roku">
                <div class="platform-inner">
                  <img src="assets/img/roku.png" alt="Roku">
                  <p>Roku OS 7.2+</p>
                  <p *ngIf="(customText.rokuAppLive && customText.rokuStoreLink) as storeLink else rokuSoon">
                    <a [href]="storeLink" target="_blank">Roku Channel</a>
                  </p>
                  <ng-template #rokuSoon>
                    <p class="coming-soon"><em>Coming Soon!</em></p>
                  </ng-template>
                </div>
              </div>
              <div class="col-auto platform platform-chromecast">
                <div class="platform-inner">
                  <img src="assets/img/chromecast.png" alt="Chromecast">
                  <p>
                    Cast from an app on your<br>
                    phone or computer.
                  </p>
                </div>
              </div>
            </ng-template>

            <ng-template [ngIf]="customText.amazonEnabled">
              <div class="col-auto platform platform-amazonfiretv">
              <div class="platform-inner">
                <img src="assets/img/amazonfiretv.png" alt="FireTV">
                <p>
                  Fire OS 5+<br>
                </p>
                <p *ngIf="(customText.firetvAppLive && customText.amazonStoreLink) as storeLink else amazonSoon">
                  <a [href]="storeLink" target="_blank">Amazon Appstore</a>
                </p>
                <ng-template #amazonSoon>
                  <p class="coming-soon"><em>Coming Soon!</em></p>
                </ng-template>
              </div>
            </div>
            </ng-template>
          </div>
        </section>
        <section class="platforms-section platforms-section-mobile">
          <h3 class="text-center">Watch On Your Mobile Device</h3>
          <div class="row">
            <ng-template [ngIf]="customText.appleStoreEnabled">
              <div class="col-auto platform platform-iphone">
                <div class="platform-inner">
                  <img src="assets/img/iphone.png" alt="iPhone">
                  <p>iOS 11.2+</p>
                  <p *ngIf="(customText.iosAppLive && customText.iphoneStoreLink) as storeLink else iphoneSoon">
                    <a [href]="storeLink" target="_blank">App Store</a>
                  </p>
                  <ng-template #iphoneSoon>
                    <p class="coming-soon"><em>Coming Soon!</em></p>
                  </ng-template>
                </div>
              </div>
              <div class="col-auto platform platform-ipad">
                <div class="platform-inner">
                  <img src="assets/img/ipad.png" alt="iPad">
                  <p>iOS 11.2+</p>
                  <p *ngIf="(customText.iosAppLive && customText.ipadStoreLink) as storeLink else ipadSoon">
                    <a [href]="storeLink" target="_blank">App Store</a>
                  </p>
                  <ng-template #ipadSoon>
                    <p class="coming-soon"><em>Coming Soon!</em></p>
                  </ng-template>
                  </div>
              </div>
            </ng-template>

            <ng-template [ngIf]="customText.googlePlayEnabled">
              <div class="col-auto platform platform-android">
                <div class="platform-inner">
                  <img src="assets/img/android.png" alt="Android">
                  <p>Android 4.4+</p>
                  <p *ngIf="(customText.androidAppLive && customText.androidStoreLink) as storeLink else androidSoon">
                    <a [href]="storeLink" target="_blank">Google Play</a>
                  </p>
                  <ng-template #androidSoon>
                    <p class="coming-soon"><em>Coming Soon!</em></p>
                  </ng-template>
                </div>
              </div>
            </ng-template>

            <ng-template [ngIf]="customText.amazonEnabled">
              <div class="col-auto platform platform-fire">
                <div class="platform-inner">
                  <img src="assets/img/amazonfire.png" alt="Fire">
                  <p>Fire OS 5+</p>
                  <p *ngIf="(customText.fireAppLive && customText.amazonStoreLink) as storeLink else amazonSoon">
                    <a [href]="storeLink" target="_blank">Amazon Appstore</a>
                  </p>
                  <ng-template #amazonSoon>
                    <p class="coming-soon"><em>Coming Soon!</em></p>
                  </ng-template>
                </div>
              </div>
            </ng-template>

          </div><!-- / .row -->
        </section>

        <section>
          <h3 class="text-center">Watch On Your Computer</h3>
          <div class="row justify-content-md-center">
            <div class="col-8 mb-3 text-white">
              {{ origin }}
            </div>
          </div>
          <div class="row justify-content-md-center">
            <div class="col-4">
              <ul>
                <li><strong>Desktop</strong></li>
                <li>Chrome 58+</li>
                <li>Firefox 52+</li>
                <li>Internet Explorer 11+</li>
                <li>MS Edge 14+</li>
                <li>Safari 10+</li>
                <li>iOS 6+</li>
                <li>Android 4+</li>
              </ul>
            </div>
            <div class="col-4">
              <ul>
                <li><strong>Mobile Devices</strong></li>
                <li>Safari on iOS 9+</li>
                <li>Chrome on Android 4+</li>
              </ul>
            </div>
          </div>
        </section>
      </div><!-- end .modal-body -->
    </div>
  </div>
</div>
