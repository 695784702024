<div class="progress">
  <div
    class="progress-bar"
    role="progressbar"
    [style.width.%]="value"
    [attr.aria-valuenow]="value"
    aria-valuemin="0"
    aria-valuemax="100">
  </div>
</div>
