<ng-container *ngIf="continue$ | async as video">
  <article
    class="continue"
    role="alert"
    *ngIf="!closed"
    @fade>
    <button class="close" aria-label="Close" (click)="closed = true">
      <svg-icon src="assets/icons/material-design-icons/close/sharp.svg"></svg-icon>
    </button>
    <div class="continue-row">
      <div class="continue-figure">
        <a
          class="continue-figure-link"
          [ngStyle]="{ 'background-image': 'url(' + video.thumb320_url + ')' }"
          [routerLink]="['', { outlets: { video: [video.id], channel: [video.Channel.id] } }]">
          <span class="continue-center">Continue Watching</span>
        </a>
        <odm-progress [value]="video.data?.progress * 100"></odm-progress>
      </div>
      <div class="continue-body">
        <span class="continue-prompt">Continue Watching</span>
        <span class="continue-meta">{{ video.Channel.title }}</span>
        <h1>{{ video.title }}</h1>
        <div class="continue-body-actions">
          <a class="btn btn-primary" [routerLink]="['', { outlets: { video: [video.id], channel: [video.Channel.id] } }]">Resume</a>
          <button class="btn btn-outline-secondary" (click)="dismiss(video.data?.id)">Dismiss</button>
        </div>
      </div>
    </div>
  </article>
</ng-container>
