<div class="jumbotron jumbotron-fluid" [ngClass]="heroClass" [ngStyle]="heroStyle">
  <div *ngIf="content.introduction_background_format === 'video' && content.background_video.conversion_720p_url"
    class="jumbotron-video-wrapper">
    <div class="jumbotron-video-layer"
      [ngStyle]="{ 'background-image': 'url(' + content.background_video.thumb1280_url + ')' }">
      <video autoplay muted loop playsinline oncanplay="this.muted = true; this.play()">
        <source [src]="content.background_video.conversion_720p_url" type="video/mp4">
      </video>
    </div>
  </div><!-- .jumbotron-video-wrapper -->
  <div class="container">
    <div class="jumbotron-row">
      <div class="jumbotron-aside">
        <a *ngIf="content.featured_section === 'video'"
          class="jumbotron-video-thumbnail-wrap" [routerLink]="['', { outlets: { video: [content.intro_video?.id] } }]"
          [attr.aria-label]="'Watch video: ' + content.intro_video?.title">
          <img [src]="content.intro_video?.thumb720_url" [alt]="'Image from ' + content.intro_video?.title">
          <span class="jumbotron-video-icon">
            <svg-icon src="assets/icons/material-design-icons/play_circle_filled_white/sharp.svg"></svg-icon>
          </span>
        </a>
        <ng-container *ngIf="content.featured_section === 'channel'">
          <div class="jumbotron-video-thumbnail-wrap">
            <img *ngIf="content.intro_channel?.thumb_rect?.url" [src]="content.intro_channel?.thumb_rect?.slir_1280_url" [alt]="'Image from ' + content.intro_channel?.title">
          </div>
        </ng-container>

        <odm-hero-actions
          [authenticated]="authenticated"
          [content]="content"
          [visible]="getActionsVisibility('jumbotron-aside')">
        </odm-hero-actions>
      </div><!-- .jumbotron-aside -->
      <div class="jumbotron-body">
        <div class="jumbotron-figure"
          *ngIf="content.featured_section != 'video'"
          [ngClass]="content.introduction_image_size"
          [ngSwitch]="content.introduction_feature_image">
          <img *ngSwitchCase="'use_default_logo'" [src]="account.logo.url">
          <img *ngSwitchCase="'use_uploaded_image'" [src]="content.intro_feature_image.url">
        </div>
        <h1>{{ content.introduction_headline }}</h1>
        <p class="jumbotron-body-copy">{{ content.introduction_supporting_text }}</p>
        <ng-container *ngIf="content.featured_section === 'custom'">
          <a class="btn btn-primary" href="{{ content.custom_button_url }}">{{ content.custom_button_text }}</a>
        </ng-container>
        <odm-hero-actions
          [authenticated]="authenticated"
          [content]="content"
          [visible]="getActionsVisibility('jumbotron-body')">
        </odm-hero-actions>
      </div><!-- .jumbotron-body -->
    </div><!-- .jumbotron-row -->
  </div><!--  .container -->
</div>
