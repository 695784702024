<footer [class.d-none]="(routeData$ | async)?.footer === false">
  <div class="footer-inner" *ngIf="account$ | async as account">
    <div class="footer-top-row">
      <div class="container">
        <div class="footer-branding">
          <odm-account-logo class="logo" [account]="account"></odm-account-logo>
        </div>
        <nav class="nav" role="navigation" aria-label="Supplemental navigation">
          <a class="nav-link" href="https://odeum.io/terms/subscriber-tos.html" target="_blank">Terms of Service</a>
          <button class="nav-link" (click)="openIubendaModal()">Privacy Policy</button>
          <button class="nav-link" (click)="openIubendaModal('cookie-policy');">Cookies</button>
          <button class="nav-link" (click)="openHelpModal()">Help &amp; Support</button>
          <button *ngIf="account.disclaimer_enabled" class="nav-link" (click)="openDisclaimerModal()">Disclaimer</button>
          <a class="nav-link" routerLink="/support/faq">FAQS</a>
        </nav>
      </div>
    </div>
    <div class="footer-bottom-row">
      <div class="container">
        <div class="footer-fineprint">
          &copy;{{ year }} - {{ account.name }} &nbsp;|&nbsp; All rights reserved
        </div>
        <div class="footer-social">
          <a
            *ngIf="account.facebook_link"
            class="social-link"
            [href]="account.facebook_link"
            [attr.aria-label]="account.name + ' Facebook'"
            target="_blank"
          >
            <svg-icon class="social-icon" src="assets/icons/custom-icons/social-facebook.svg"></svg-icon>
          </a>
          <a
            *ngIf="account.twitter_link"
            class="social-link"
            [href]="account.twitter_link"
            [attr.aria-label]="account.name + ' Twitter'"
            target="_blank"
          >
            <svg-icon class="social-icon" src="assets/icons/custom-icons/social-twitter.svg"></svg-icon>
          </a>
          <a
            *ngIf="account.instagram_link"
            class="social-link"
            [href]="account.instagram_link"
            [attr.aria-label]="account.name + ' Instagram'"
            target="_blank"
          >
            <svg-icon class="social-icon" src="assets/icons/custom-icons/social-instagram.svg"></svg-icon>
          </a>
        </div>
      </div>
    </div>
  </div>
</footer>
