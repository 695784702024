<div class="modal-backdrop show" @fadeIn></div>
<div class="modal d-block" tabindex="-1" role="dialog">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Share This {{ options.type }}</h5>
        <button type="button" class="close" aria-label="Close" (click)="close()">
          <svg-icon src="assets/icons/material-design-icons/close/sharp.svg"></svg-icon>
        </button>
      </div>
      <div class="modal-body">
        <div class="form-group">
          <label for="permalink">{{ options.type }} Permalink</label>
          <div class="input-group">
            <input id="permalink" class="form-control" type="text" readonly [value]="href">
            <div class="input-group-append">
              <button class="btn btn-primary" type="button" (click)="copyToClipboard()">
                <svg-icon src="assets/icons/material-design-icons/assignment/outline.svg"></svg-icon>
                Copy
              </button>
            </div>
          </div>
        </div>
        <p *ngIf="message$ | async">Copied!</p>
      </div><!-- end .modal-body -->
    </div>
  </div>
</div>
