<ng-container *ngIf="loaded">
  <odm-carousel *ngIf="videos.length > 0 else emptyVideos">
    <odm-video-item
      *ngFor="let video of videos"
      odmCarouselItem
      [video]="video"
      [channelId]="channelId">
    </odm-video-item>
  </odm-carousel>
  <ng-template #emptyVideos>
    <div class="alert alert-info" role="alert">
      {{ emptyMessage }}
    </div>
  </ng-template>
</ng-container>
<div class="text-center" *ngIf="loading">
  <div class="spinner-border" role="status">
    <span class="sr-only">Loading...</span>
  </div>
</div>
<div class="alert alert-danger" role="alert" *ngIf="error" [innerHTML]="error.error?.message || 'Error loading videos'"></div>
