<ng-container *ngIf="resources && resources.length > 0">
  <ng-container *ngIf="authenticated; else unauthenticated">
    <div class="resource-links" @fade>
      <ng-container *ngIf="resources.length > 1; else singleAuthResource">
        <div ngbDropdown>
          <button
            ngbDropdownToggle
            id="resource-group-{{ randomId }}"
            class="btn btn-clear-icon">
            {{ label }} Resources ({{ resources.length }})
            <figure class="btn-taxonomy">
              <svg-icon src="assets/icons/material-design-icons/attachment/sharp.svg" class="rotate-315"></svg-icon>
            </figure>
            <svg-icon class="dropdown-state" src="assets/icons/material-design-icons/clear/sharp.svg"></svg-icon>
          </button>
          <div
            ngbDropdownMenu
            [attr.aria-labelledby]="'resource-group-'+ randomId">
            <a
              *ngFor="let resource of resources"
              ngbDropdownItem
              href="{{ resource.Resource.url }}"
              target="_blank">
                {{ resource.Resource.name }}
                <svg-icon *ngIf="resource.Resource.type === 'url'; else docIcon" class="rotate-315" src="assets/icons/material-design-icons/arrow_forward/sharp.svg"></svg-icon>
                <ng-template #docIcon>
                  <svg-icon src="assets/icons/material-design-icons/get_app/outline.svg"></svg-icon>
                </ng-template>
            </a>
          </div>
        </div>
      </ng-container>

      <ng-template #singleAuthResource>
        <a
          class="btn btn-clear-icon"
          href="{{ resources[0].Resource.url }}"
          target="_blank">
          <figure class="btn-taxonomy">
            <svg-icon *ngIf="resources[0].Resource.type === 'url'; else docIconSingle" class="rotate-315" src="assets/icons/material-design-icons/arrow_forward/sharp.svg"></svg-icon>
            <ng-template #docIconSingle>
              <svg-icon src="assets/icons/material-design-icons/get_app/outline.svg"></svg-icon>
            </ng-template>
          </figure>
          {{ resources[0].Resource.name }}
        </a>
      </ng-template>
    </div>
  </ng-container>

  <ng-template #unauthenticated>
    <div class="resource-links" @fade>
      <button
        class="btn btn-clear-icon is-locked"
        [ngbTooltip]="resourceTip"
        triggers="click"
        placement="bottom"
        [autoClose]="'outside'">
        <ng-container *ngIf="resources.length > 1; else singleUnauthResource">
          {{ label }} Resources ({{ resources.length }})
        </ng-container>
        <ng-template #singleUnauthResource>
          {{ resources[0].Resource.name }}
        </ng-template>
        <figure class="btn-taxonomy">
          <svg-icon src="assets/icons/material-design-icons/lock/outline.svg"></svg-icon>
        </figure>
      </button>

      <ng-template #resourceTip>
        <div @fade>
          <button class="btn btn-quiet" (click)="openAuthModal()">Sign In</button> to view this resource link.<br> Don't have an account yet? <a href="/user/subscribe">Start Your Free Trial</a>
        </div>
      </ng-template>
    </div>
  </ng-template>
</ng-container>
