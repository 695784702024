
<div class="section d-flex align-items-center">
  <div class="text-center mx-auto mw-500" *ngIf="account$ | async as account">
    <div class="brand mb-5">
      <img
      [src]="account.nav_logo.url"
      [alt]="account.name">
      {{ account.name }}
    </div>
    <h1>Coming Soon</h1>
    <hr class="mx-n5 my-4">
    <p>Future home of {{ account.name }}</p>
  </div>
</div>
