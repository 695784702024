<div class="modal-backdrop show" @fadeIn></div>
<div class="modal d-block" tabindex="-1" role="dialog">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header text-center">
        <h2 class="modal-title" style="font-size: 24px;">Please confirm you would like to cancel your subscription</h2>
        <button class="close" aria-label="Close" (click)="close()">
          <svg-icon src="assets/icons/material-design-icons/close/sharp.svg"></svg-icon>
        </button>
      </div>
      <div class="modal-body text-center">
        <ng-container *ngIf="loaded$ | async else notLoaded">
          <div class="alert alert-success" role="alert" *ngIf="message$ | async as message" [innerHTML]="message"></div>
        </ng-container>
        <ng-template #notLoaded>
          <p *ngIf="locked$ | async else notLocked">
            Your cancellation request will be processed and your access to this site will be terminated immediately. No further billing attempts will be made to your credit card.
          </p>
          <ng-template #notLocked>
            <p *ngIf="user$ | async as user">
              Your cancellation request will be processed now, and you will retain access to all content through the end of the current billing cycle that ends on {{ formatDate(user.Subscription.current_period_end, 'LLL') }}. There will be no further subscription billing to your credit card.
            </p>
          </ng-template>
          <ng-container *ngIf="loading$ | async | yesNo as loading">
            <button
              class="btn btn-outline-secondary"
              [disabled]="loading === 'yes'"
              (click)="cancelSubscription()">
              <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" *ngIf="loading === 'yes'"></span>
              Cancel Subscription
            </button>
          </ng-container>
          <div class="alert alert-danger" role="alert" *ngIf="error$ | async as error" [innerHTML]="error.error?.message || 'Resend Email Error'"></div>
          <button class="btn btn-quiet btn-block mt-3" (click)="close()">
            Just kidding. Keep my subscription
          </button>
        </ng-template>
      </div><!-- end .modal-body -->
    </div>
  </div>
</div>
