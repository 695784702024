import { createFeatureSelector, ActionReducerMap } from '@ngrx/store';

import * as fromAccount from './account/account.reducer';
import * as fromChannels from './channels/channels.reducer';
import * as fromConfirm from './confirm/confirm.reducer';
import * as fromDeletePendingSubscription from './delete-pending-subscription/delete-pending-subscription.reducer';
import * as fromDevice from './device/device.reducer';
import * as fromLoading from './loading/loading.reducer';
import * as fromMarketingContent from './marketing-content/marketing-content.reducer';
import * as fromModals from './modals/modals.reducer';
import * as fromPromotion from './promotion/promotion.reducer';
import * as fromResources from './resources/resources.reducer';
import * as fromStreamKey from './stream-key/stream-key.reducer';
import * as fromSubscriberContent from './subscriber-content/subscriber-content.reducer';
import * as fromSubscriptionEmail from './subscription-email/subscription-email.reducer';
import * as fromToasts from './toasts/toasts.reducer';
import * as fromUser from './user/user.reducer';
import * as fromVideoUserData from './video-user-data/video-user-data.reducer';
import * as fromVideos from './videos/videos.reducer';

export const featureKey = 'core';

export interface State {
  account: fromAccount.State;
  channels: fromChannels.State;
  confirm: fromConfirm.State;
  deletePendingSubscription: fromDeletePendingSubscription.State;
  device: fromDevice.State;
  loading: fromLoading.State;
  marketingContent: fromMarketingContent.State;
  modals: fromModals.State;
  promotion: fromPromotion.State;
  resources: fromResources.State;
  streamKey: fromStreamKey.State;
  subscriberContent: fromSubscriberContent.State;
  subscriptionEmail: fromSubscriptionEmail.State;
  toasts: fromToasts.State;
  user: fromUser.State;
  videoUserData: fromVideoUserData.State;
  videos: fromVideos.State;
}

export const reducers: ActionReducerMap<State> = {
  account: fromAccount.reducer,
  channels: fromChannels.reducer,
  confirm: fromConfirm.reducer,
  deletePendingSubscription: fromDeletePendingSubscription.reducer,
  device: fromDevice.reducer,
  loading: fromLoading.reducer,
  marketingContent: fromMarketingContent.reducer,
  modals: fromModals.reducer,
  promotion: fromPromotion.reducer,
  resources: fromResources.reducer,
  streamKey: fromStreamKey.reducer,
  subscriberContent: fromSubscriberContent.reducer,
  subscriptionEmail: fromSubscriptionEmail.reducer,
  toasts: fromToasts.reducer,
  user: fromUser.reducer,
  videoUserData: fromVideoUserData.reducer,
  videos: fromVideos.reducer,
};

export const selectCoreState = createFeatureSelector<State>(featureKey);
