<div class="modal-backdrop show" @fadeIn></div>
<div
  class="modal d-block"
  tabindex="-1"
  role="dialog"
  (scroll)="onScroll()">
  <div class="modal-dialog modal-splash" role="document">
    <div class="modal-content" [class.at-top]="atTop">
      <ng-container *ngIf="channel$ | async as channel">
        <div class="modal-header modal-splash-header" #modalHeader>
          <div class="modal-header-wallpaper" [ngStyle]="{ 'background-image': 'url(' + getChannelImage(channel.thumb_rect?.slir_1280_url) + ')' }"></div>
          <div
            class="modal-super-header"
            [style.width.px]="atTop === true ? superHeaderWidth : null"
            [style.left.px]="atTop === true ? superHeaderXPos : null">
            <h6>{{ channel.title }}</h6>
            <button class="close" aria-label="Close" [routerLink]="['', { outlets: { channel: null } }]">
              <svg-icon src="assets/icons/material-design-icons/close/sharp.svg"></svg-icon>
            </button>
          </div>
          <div class="modal-header-content">
            <h2 class="modal-title">{{ channel.title }}</h2>
            <ul class="modal-header-details">
              <li [ngPlural]="channel.videos.length">
                {{ channel.videos.length }}
                <ng-template ngPluralCase="one">Video</ng-template>
                <ng-template ngPluralCase="other">Videos</ng-template>
              </li>
              <li *ngIf="getNewCount(channel) as count">{{ count }} New</li>
            </ul>
            <p class="modal-header-copy">{{ channel.description }}</p>
            <div class="modal-header-actions">
              <a
                class="btn btn-primary"
                *ngIf="!(authenticated$ | async)"
                [routerLink]="['/', { outlets: { primary: 'user/subscribe', channel: null } }]">
                {{ customText.subscribeText }}
              </a>
              <a
                class="btn"
                [class.btn-primary]="authenticated$ | async"
                [class.btn-outline-secondary]="!(authenticated$ | async)"
                *ngIf="channel.trailer"
                [queryParams]="{a: 1}"
                [routerLink]="['', { outlets: { video: [channel.trailer.id] } }]">
                <svg-icon src="assets/icons/material-design-icons/play_circle_filled_white/outline.svg"></svg-icon>
                Watch Trailer
              </a>
              <button class="btn btn-outline-secondary" (click)="openShareModal(channel.id)">
                <svg-icon src="assets/icons/material-design-icons/share/outline.svg"></svg-icon>
                Share
              </button>
            </div>
          </div>
        </div>
        <div class="modal-body">
          <div class="channel-filter form-inline">
            <div *ngIf="channel.display_tags" class="form-group">
              <label for="channel-filter">{{ customText.tagsTerm }}:</label>
              <select
                id="channel-filter"
                class="form-control tag-filters"
                (change)="onFilter(filters.value)"
                #filters>
                <option value="">All</option>
                <option
                  *ngFor="let tag of channel.tags"
                  [value]="tag">
                  {{ tag }}
                </option>
              </select>
            </div>
            <odm-resource-links
              [authenticated]="authenticated$ | async"
              [resources]="resources$ | async"
              [label]="customText.channelTerm">
            </odm-resource-links>
          </div>
          <section>
            <div class="tiles">
              <odm-video-item
                class="tile"
                *ngFor="let video of getFilteredVideos(channel.videos)"
                [video]="video"
                [channelId]="channel.id">
              </odm-video-item>
            </div>
          </section>
        </div><!-- end .modal-body -->
      </ng-container>
    </div><!-- end .modal-content -->
  </div><!-- end .modal-dialog -->
</div><!-- end .modal -->
