<odm-locked></odm-locked>
<odm-promotion></odm-promotion>
<header
  [class.at-top]="navAtTop$ | async"
  [class.is-expanded]="showMenuPane"
  [class.d-none]="(routeData$ | async)?.nav === false"
  (odmClickOutside)="showMenuPane = false"
  #siteHeader>
  <nav
    class="navbar navbar-expand-xl"
    role="navigation"
    aria-label="Site navigation">
    <div class="container-fluid container-fluid-nowrap-mobile">
      <ng-container *ngIf="account$ | async as account">
        <a class="navbar-brand" [ngClass]="{'navbar-brand--font-reduce' : account.name.length > 17 && account.main_nav_format === 'channel_name_and_logo'}" routerLink="/" (click)="showMenuPane = false">

          <odm-account-logo [account]="account"></odm-account-logo>
        </a>
        <a
          class="nav-link btn-search btn-search-alt"
          routerLink="search"
          routerLinkActive="active"
          (click)="showMenuPane = false"
          aria-label="Search">
          <svg-icon src="assets/icons/material-design-icons/search/sharp.svg"></svg-icon>
        </a>
        <button
          class="navbar-toggler"
          [class.active]="showMenuPane"
          type="button"
          (click)="showMenuPane = !showMenuPane"
          aria-controls="navbar-menu-wrap"
          aria-expanded="showMenuPane"
          [attr.aria-label]="!showMenuPane ? 'Open Menu' : 'Close Menu'">
          <svg-icon *ngIf="!showMenuPane" src="assets/icons/material-design-icons/menu/sharp.svg"></svg-icon>
          <svg-icon *ngIf="showMenuPane" src="assets/icons/material-design-icons/close/sharp.svg"></svg-icon>
          <span>{{ !showMenuPane ? 'Menu' : 'Close' }}</span>
        </button>
        <div
          id="navbar-menu-wrap"
          class="collapse navbar-collapse"
          [class.show]="showMenuPane">
          <div class="navbar-nav mr-auto">
            <a
              class="nav-link"
              routerLink=""
              routerLinkActive="active"
              [routerLinkActiveOptions]="{exact: true}"
              (click)="showMenuPane = false">
              Home
            </a>
            <a
              class="nav-link"
              routerLink="channels"
              routerLinkActive="active"
              (click)="showMenuPane = false">
              {{ account.channels_term }}
            </a>
            <!-- <a
              *ngIf="account.FeaturedNavChannel?.id"
              class="nav-link"
              [routerLink]="['', { outlets: { channel: [account.FeaturedNavChannel.id] } }]"
              (click)="showMenuPane = false">
              {{ account.FeaturedNavChannel.title }}
            </a> -->
            <ng-container *ngIf="content$ | async as content">
              <ng-container *ngIf="authLoaded$ | async">
                <ng-container *ngIf="authenticated$ | async else notAuthLinks">
                  <a
                    *ngIf="content.display_nav_link"
                    class="nav-link btn-external"
                    [href]="content.nav_link_destination"
                    target="_blank">
                    {{ content.nav_link_text | truncate: 30 }}
                    <svg-icon src="assets/icons/material-design-icons/launch/sharp.svg"></svg-icon>
                  </a>
                </ng-container>
                <ng-template #notAuthLinks>
                  <a
                    *ngIf="content.display_nav_link"
                    class="nav-link btn-external"
                    [href]="content.nav_link_destination"
                    target="_blank">
                    {{ content.nav_link_text | truncate: 30 }}
                    <svg-icon src="assets/icons/material-design-icons/launch/sharp.svg"></svg-icon>
                  </a>
                </ng-template>
              </ng-container>
            </ng-container>
          </div>
          <div class="navbar-nav">
            <a
              class="nav-link btn-search"
              routerLink="search"
              routerLinkActive="active"
              (click)="showMenuPane = false"
              aria-label="Search">
              <svg-icon src="assets/icons/material-design-icons/search/sharp.svg"></svg-icon>
              <span>Search</span>
            </a>
            <ng-container *ngIf="authLoaded$ | async">
              <ng-container *ngIf="authenticated$ | async else notAuthenticated">
                <a
                  class="nav-link"
                  routerLink="user/videos"
                  routerLinkActive="active"
                  (click)="showMenuPane = false">
                  <svg-icon src="assets/icons/material-design-icons/collections_bookmark/outline.svg"></svg-icon>
                  My Stuff
                </a>
                <a
                  class="nav-link"
                  routerLink="user/account"
                  routerLinkActive="active"
                  (click)="showMenuPane = false">
                  <svg-icon src="assets/icons/material-design-icons/account_circle/outline.svg"></svg-icon>
                  Account
                </a>
              </ng-container>
              <ng-template #notAuthenticated>
                <a
                  class="btn btn-outline-light"
                  routerLink="user/subscribe"
                  routerLinkActive="active"
                  (click)="showMenuPane = false">
                  {{ account.primary_cta_text }}
                </a>
                <button
                  class="btn btn-link btn-sign-in"
                  (click)="openAuthModal()"
                  (click)="showMenuPane = false">
                  <svg-icon src="assets/icons/custom-icons/sign-in.svg"></svg-icon>
                  Sign In
                </button>
              </ng-template>
            </ng-container>
          </div>
        </div>
      </ng-container>
    </div><!-- end .container -->
  </nav>
  <odm-progress-indeterminate *ngIf="loading$ | async"></odm-progress-indeterminate>
</header>
