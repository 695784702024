<div class="modal-backdrop show" @fadeIn></div>
<div class="modal d-block" tabindex="-1" role="dialog">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h2 class="modal-title">{{ options.title }}</h2>
        <button class="close" aria-label="Close" (click)="close()">
          <svg-icon src="assets/icons/material-design-icons/close/sharp.svg"></svg-icon>
        </button>
      </div>
      <div class="modal-body">
       <p>{{ options.message }}</p>
       <div class="d-flex justify-content-end mt-5">
        <button class="btn btn-outline-secondary" (click)="close()">Cancel</button>
        <button class="btn btn-primary" (click)="confirm()">{{ options.button }}</button>
       </div>
      </div><!-- end .modal-body -->
    </div>
  </div>
</div>
