<div class="modal-backdrop show" @fadeIn></div>
<div class="modal d-block" tabindex="-1" role="dialog">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h2 class="modal-title mx-auto">Unactivated Subscription Found!</h2>
        <button class="close" aria-label="Close" (click)="close()">
          <svg-icon src="assets/icons/material-design-icons/close/sharp.svg"></svg-icon>
        </button>
      </div>
      <div class="modal-body text-center">
        <p [innerHTML]="trustHtml(options.message)"></p>
        <div *ngIf="!(deletePendingSubscriptionLoaded$ | async)"><!-- resend activation -->
          <div class="alert alert-danger" role="alert" *ngIf="resendSubscriptionEmailError$ | async as error" [innerHTML="error.error?.message || 'Resend Email Error'"></div>
          <ng-container *ngIf="resendSubscriptionEmailLoading$ | async | yesNo as loading">
            <button
              class="btn btn-primary"
              [disabled]="loading === 'yes'"
              (click)="resendSubscriptionEmail()">
              <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" *ngIf="loading === 'yes'"></span>
              Resend Activation Email
            </button>
          </ng-container>
        </div><!-- end resend activation -->
        <div class="mt-3"><!-- delete sub -->
          <ng-container *ngIf="deletePendingSubscriptionLoaded$ | async; else notDeleteLoaded">
            <div class="alert alert-success" role="alert" [innerHTML]="deletePendingSubscriptionMessage$ | async"></div>
          </ng-container>
          <ng-template #notDeleteLoaded>
            <div class="alert alert-danger" role="alert" *ngIf="deletePendingSubscriptionError$ | async as error" [innerHTML]="error.error?.message || 'Delete Subscription Error'"></div>
            <ng-container *ngIf="deletePendingSubscriptionLoading$ | async | yesNo as loading">
              <button
                class="btn btn-quiet"
                [disabled]="loading === 'yes'"
                (click)="deletePendingSubscription()">
                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" *ngIf="loading === 'yes'"></span>
                Delete the previous unactivated subscription
              </button>
            </ng-container>
          </ng-template>
        </div><!-- end delete sub -->
      </div><!-- end .modal-body -->
    </div>
  </div>
</div>
