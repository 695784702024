<div class="row">
  <div
    *ngFor="let icon of icons"
    class="icon-column"
    [ngClass]="getColClass()">
    <div class="icon-block">
      <div *ngIf="icon.identifier" class="icon-graphic">
        <figure class="icon-figure">
          <span class="material-icons">{{icon.identifier}}</span>
        </figure>
      </div>
      <div class="icon-content">
        <h3 class="icon-title">{{ icon.text_1 }}</h3>
        <p class="icon-description">{{ icon.text_2 }}</p>
      </div>
    </div>
  </div>
</div>
