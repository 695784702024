<div class="modal-backdrop show" @fadeIn></div>
<div
  class="modal d-block"
  tabindex="-1"
  role="dialog"
  (scroll)="onScroll()">
  <div class="modal-dialog modal-splash" role="document">
    <div class="modal-content" [class.at-top]="atTop">
      <div class="modal-header modal-splash-header" #modalHeader>
        <div
          class="modal-super-header modal-super-header-alt"
          [style.width.px]="atTop === true ? superHeaderWidth : null"
          [style.left.px]="atTop === true ? superHeaderXPos : null">
          <h6>{{ video.title }}</h6>
          <button class="close" aria-label="Close" [routerLink]="['', { outlets: { video: null } }]">
            <svg-icon src="assets/icons/material-design-icons/close/sharp.svg"></svg-icon>
          </button>
        </div>
        <div class="modal-header-video" *ngIf="video.hls_url else noVideo">
          <div class="embed-responsive embed-responsive-16by9">
            <div class="embed-responsive-item">
              <div class="player-container" [class.follow]="playerFixed">
                <button class="close" aria-label="Close" (click)="cancelFollow()">
                  <svg-icon src="assets/icons/material-design-icons/close/sharp.svg"></svg-icon>
                </button>
                <div #player></div>
                <div
                  *ngIf="nextVideo"
                  class="post-play"
                  [style.backgroundImage]="'url('+video.thumb320_url+')'">
                  <div class="post-play-content">
                    <div class="post-play-inner">
                      <div class="post-play-thumbnail-wrap">
                        <img [src]="nextVideo.thumb720_url" [alt]="''">
                      </div>
                      <div class="post-play-meta">
                        <h4>{{ channel.title }}</h4>
                        <h3>{{ nextVideo.title }}</h3>
                        <p class="description">{{ nextVideo.description }}</p>
                        <p class="duration">{{ nextVideo.duration_formatted }}</p>
                        <button class="btn btn-primary timer" (click)="navigateToNextVideo()">
                          <div class="timer-inner">
                            <svg-icon src="assets/icons/material-design-icons/play_arrow/sharp.svg"></svg-icon>
                            Next Video
                          </div>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ng-template #noVideo>
          <div class="modal-header-banner" [ngStyle]="{ 'background-image': 'url(' + video.thumb1280_url + ')' }">
            <div class="modal-header-banner-content">
              <ng-container *ngIf="locked$ | async else notLocked">
                <h3>
                  Your subscription to {{ customText.accountName }} is locked.<br>
                  Update your credit card to continue.
                </h3>
                <a
                  class="btn btn-primary"
                  [routerLink]="['/', { outlets: { primary: 'user/account', channel: null, video: null } }]">
                  My Account
                </a>
              </ng-container>
              <ng-template #notLocked>
                <h3>Watch this video and more on {{ customText.accountName }}</h3>
                <a
                  class="btn btn-primary"
                  [routerLink]="['/', { outlets: { primary: 'user/subscribe', channel: null, video: null } }]">
                  {{ customText.subscribeText }}
                </a>
                <div>
                  Already subscribed?
                  <button class="btn btn-quiet" (click)="openAuthModal()">Sign In</button>
                </div>
              </ng-template>
            </div>\
          </div>
        </ng-template>
        <div class="modal-header-content modal-header-content-col">
          <div class="modal-header-content-primary">
            <div #follower></div>
            <h2 class="modal-title">{{ video.title }}</h2>
            <ul class="modal-header-details">
              <li>{{ video.duration_formatted }}</li>
            </ul>
            <p class="modal-header-copy">{{ video.description }}</p>
            <odm-resource-links
              [authenticated]="video.hls_url"
              [resources]="resources$ | async"
              label="Video">
            </odm-resource-links>
          </div>
          <div class="modal-header-content-secondary">
            <div class="modal-header-actions">
              <ng-container *ngIf="video.data">
                <odm-favorite-button
                  [favorite]="video.data.favorite"
                  [loading]="favoriteLoading$ | async"
                  (toggle)="onFavorite($event)">
                </odm-favorite-button>
              </ng-container>
              <button class="btn btn-outline-secondary" (click)="openShareModal(video.id)">
                <svg-icon src="assets/icons/material-design-icons/share/outline.svg"></svg-icon>
                Share
              </button>
            </div>
          </div>
        </div>
      </div><!-- end .modal-header -->
      <div class="modal-body">
        <!-- {{video.ChannelsVideo.channel_id}} -->
        <section *ngIf="channel">
          <h4>More videos in <strong>{{ channel.title }}</strong></h4>
          <div class="tiles">
            <odm-video-item
              class="tile"
              *ngFor="let channelVideo of channel.videos"
              [video]="channelVideo"
              [activeId]="video.id"
              [channelId]="channel.id">
            </odm-video-item>
          </div>
        </section>
      </div><!-- end .modal-body -->
    </div><!-- end .modal-content -->
  </div><!-- end .modal-dialog -->
</div><!-- end .modal -->
