<div class="modal-backdrop show" @fadeIn></div>
<div class="modal d-block" tabindex="-1" role="dialog">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h2 class="modal-title mx-auto">Existing Subscription Found!</h2>
        <button class="close" aria-label="Close" (click)="close()">
          <svg-icon src="assets/icons/material-design-icons/close/sharp.svg"></svg-icon>
        </button>
      </div>
      <div class="modal-body text-center">
        <div>
          <ng-container *ngIf="tokenLoaded$ | async; else noToken">
            <div class="alert alert-success" role="alert">
              Please check your email to confirm your login.
            </div>
          </ng-container>
          <ng-template #noToken>
            <p [innerHTML]="trustHtml(options.message)"></p>
            <div class="alert alert-danger" role="alert" *ngIf="tokenError$ | async as error" [innerHTML]="error.error?.message || 'Sign In Error'"></div>
            <ng-container *ngIf="tokenLoading$ | async | yesNo as loading">
              <button
                class="btn btn-primary"
                [disabled]="loading === 'yes'"
                (click)=auth()>
                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" *ngIf="loading === 'yes'"></span>
                Send Sign In Verification Email
              </button>
            </ng-container>
          </ng-template>
        </div>
      </div><!-- end .modal-body -->
    </div>
  </div>
</div>
