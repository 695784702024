<ng-container *ngIf="streamKey$ | async as streamKey">
  <article
    class="streaming"
    [class.d-none]="(routeData$ | async)?.banners === false"
    role="alert"
    *ngIf="!closed"
    @fade>
    <header class="streaming-masthead">
      <h3>Streaming Now</h3>
      <button class="close" aria-label="Close" (click)="closed = true">
        <svg-icon src="assets/icons/material-design-icons/close/sharp.svg"></svg-icon>
      </button>
    </header>
    <div class="streaming-row">
      <div class="streaming-figure">
        <a class="streaming-figure-link" [ngStyle]="{ 'background-image': 'url(' + streamKey.Thumb.url + ')' }"
          [routerLink]="['', { outlets: { video: [streamKey.LiveVideo.id], channel: [streamKey.Channel.id] } }]">
          <span class="streaming-center">
            <svg-icon class="play-icon" src="assets/icons/material-design-icons/play_circle_filled_white/sharp.svg"></svg-icon>
          </span>
        </a>
        <odm-progress [value]="50"></odm-progress>
      </div>
      <div class="streaming-body">
        <!-- <span class="streaming-prompt">mobile streaming text prompt... </span> -->
        <!-- <span class="streaming-meta">{{ video.Channel.title }}</span> -->
        <span class="streaming-meta">
          <span class="tag tag-live">
            <svg-icon class="bolt-icon" src="assets/icons/custom-icons/live-bolt.svg"></svg-icon> <span class="tag-live-txt">Live</span>
          </span>
          <!-- <span class="tag tag-viewers">
            <svg-icon class="eye-icon" src="assets/icons/custom-icons/live-eye.svg"></svg-icon>
            {{ streamKey.LiveVideo.views }}
          </span> -->
            <span class="elapsed">
              <odm-stopwatch [start]="streamKey.LiveVideo.elapsed"></odm-stopwatch>&nbsp;Elapsed
            </span>
          </span>
        <h1>{{ streamKey.LiveVideo.title }}</h1>
        <div class="streaming-body-actions">
          <a class="btn btn-primary"
            [routerLink]="['', { outlets: { video: [streamKey.LiveVideo.id], channel: [streamKey.Channel.id] }
            }]">{{ streamKey.StreamKey.custom_cta }}</a>
        </div>
      </div>
    </div>
  </article>
</ng-container>
