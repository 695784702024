<ng-container *ngIf="authenticated$ | async | yesNo as authenticated">
  <ng-container *ngIf="content$ | async as content">
    <section class="section-splash">
      <odm-hero
        [authenticated]="authenticated === 'yes'"
        [account]="account$ | async"
        [content]="content">
      </odm-hero>
    </section>
    <section *ngIf="content.marketing_description_slide_enabled" class="section">
      <odm-marketing
        [content]="content"
        (viewPlatforms)="openPlatformsModal()">
      </odm-marketing>
    </section>
    <section *ngIf="content.announcement_enabled" class="section">
      <odm-announcement
        [content]="content"
        (viewPlatforms)="openPlatformsModal()">
      </odm-announcement>
    </section>
    <section *ngIf="content.value_prop_enabled" class="section has-background">
      <odm-features [content]="content"></odm-features>
      <div *ngIf="content.value_propositions_image?.url as url" class="feature-image">
        <img [src]="url">
      </div>
    </section>
    <section
      *ngIf="content.preview_content_enabled && content.preview_video_list"
      class="section">
      <odm-page-channel [content]="content"></odm-page-channel>
    </section>
    <section *ngIf="content.eval_metrics_enabled" class="section">
      <odm-metrics [content]="content"></odm-metrics>
    </section>
    <section *ngIf="content.devices_enabled" class="section">
      <odm-watch-anywhere [content]="content"></odm-watch-anywhere>
    </section>
    <section *ngIf="authenticated === 'yes'" class="section">
      <div class="container">
        <ng-container *ngIf="content.continue_watching_section && content.continue_videos.length">
          <div class="section-sub-intro">
            <div class="section-sub-intro-title">
              <h3>Continue watching</h3>
            </div>
          </div>
          <odm-video-carousel
            [videos]="content.continue_videos"
            [loaded]="true"
            [loading]="false"
            emptyMessage="You have not watched any videos yet.">
          </odm-video-carousel>
        </ng-container>
        <ng-container *ngIf="content.featured_content_enabled && content.featured_channel">
          <div class="section-sub-intro">
            <div class="section-sub-intro-title">
              <h3>{{ content.featured_channel.title }}</h3>
              <div>
                <a [routerLink]="['', { outlets: { channel: [content.featured_channel.id] } }]">Browse {{ customText.channelTerm }}</a>
              </div>
            </div>
          </div>
          <odm-video-carousel
            [videos]="content.featured_channel.videos"
            [channelId]="content.featured_channel.id"
            [loaded]="true"
            [loading]="false"
            emptyMessage="No videos yet.">
          </odm-video-carousel>
        </ng-container>
        <ng-container *ngIf="content.new_video_section && content.new_videos.length">
          <div class="section-sub-intro">
            <div class="section-sub-intro-title">
              <h3>The Latest from {{ customText.accountName }}</h3>
              <div>
                <a routerLink="/channels">Browse All</a>
              </div>
            </div>
            <p>Check out these recent videos from {{ customText.accountName }}!</p>
          </div>
          <odm-video-carousel
            [videos]="content.new_videos"
            [loaded]="true"
            [loading]="false"
            emptyMessage="No new videos currently.">
          </odm-video-carousel>
        </ng-container>
      </div>
    </section>
  </ng-container>
</ng-container>
<odm-device-install
  *ngIf="customText.iphoneStoreLink || customText.ipadStoreLink || customText.androidStoreLink"
  [loaded]="textCommLoaded$ | async"
  [loading]="textCommLoading$ | async"
  [error]="textCommError$ | async"
  (sendText)="onSendText($event)">
</odm-device-install>
