<div
  class="tile-wrapper"
  [class.is-hovered]="tileHovered">
  <div class="tile-content">
    <div class="tile-head">
      <a
        class="tile-figure"
        [ngStyle]="{ 'background-image': 'url(' + video.thumb320_url + ')' }"
        [routerLink]="link"
        (mouseenter)="toggleHover()"
        (mouseleave)="toggleHover()"
        (focus)="toggleHover()"
        (focusout)="toggleHover()">
        <span class="tile-flags">
          <span class="tile-flag" *ngIf="video.data?.new">New</span>
          <span class="tile-flag tile-flag-live" *ngIf="video.live">
            <svg-icon class="bolt-icon" src="assets/icons/custom-icons/live-bolt.svg"></svg-icon> Live
          </span>
        </span>
        <span class="tile-center" *ngIf="activeId === video.id">Playing</span>
        <span class="tile-cta">
          <svg-icon src="assets/icons/material-design-icons/play_circle_outline/outline.svg"></svg-icon>
        </span>
        <span class="tile-caption" *ngIf="video.live; else duration">
          <odm-stopwatch [start]="video.elapsed"></odm-stopwatch>&nbsp;Elapsed
        </span>
        <ng-template #duration>
          <span class="tile-caption">{{ video.duration_formatted }}</span>
        </ng-template>
      </a>
      <div class="tile-actions">
        <button
          class="tile-action"
          type="button"
          *ngIf="video.data"
          (mouseenter)="tileHovered = true"
          (mouseleave)="tileHovered = false"
          (click)="favorite(!video.data.favorite)"
          [disabled]="favoriteLoading"
          tabindex="-1">
          <svg-icon class="tile-icon" src="assets/icons/material-design-icons/favorite/outline.svg" *ngIf="video.data.favorite"></svg-icon>
          <svg-icon class="tile-icon tile-icon-light" src="assets/icons/material-design-icons/favorite_border/outline.svg" *ngIf="!video.data.favorite"></svg-icon>
        </button>
        <svg-icon class="tile-icon tile-icon-light" src="assets/icons/material-design-icons/lock/outline.svg" *ngIf="!video.hls_url"></svg-icon>
      </div>
      <odm-progress [value]="video.data?.progress * 100"></odm-progress>
    </div>
    <div class="tile-body">
      <div class="tile-body-background"></div>
      <h3 class="tile-title">{{ video.title }}</h3>
      <div class="tile-text">{{ video.description | truncate: 100 }}</div>
    </div>
  </div><!-- end .tile-content -->
</div><!-- end .tile-wrapper -->
