

<ng-container *ngIf="favorite else notFavorite">
  <button
    class="btn btn-primary"
    [disabled]="loading"
    (click)="onClick(false)">
    <ng-container *ngIf="loading else notLoading">
      <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
      Favorite
    </ng-container>
    <ng-template #notLoading>
      <span class="not-hovered">
        <svg-icon src="assets/icons/material-design-icons/favorite/outline.svg"></svg-icon>
        Favorite
      </span>
      <span class="hovered">
        <svg-icon src="assets/icons/material-design-icons/favorite_border/outline.svg"></svg-icon>
        Remove &nbsp;
      </span>
    </ng-template>
  </button>
</ng-container>
<ng-template #notFavorite>
  <button
    class="btn btn-outline-secondary"
    [disabled]="loading"
    (click)="onClick(true)">
    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" *ngIf="loading else notLoading"></span>
    <ng-template #notLoading>
      <svg-icon src="assets/icons/material-design-icons/favorite_border/outline.svg"></svg-icon>
    </ng-template>
    Favorite
  </button>
</ng-template>
