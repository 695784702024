<odm-nav></odm-nav>
<div id="content">
  <router-outlet (activate)="scrollToTop()"></router-outlet>
</div>
<odm-footer></odm-footer>
<odm-install-prompt-bar></odm-install-prompt-bar>
<odm-continue></odm-continue>
<odm-streaming-now></odm-streaming-now>
<router-outlet name="channel"></router-outlet>
<router-outlet name="video"></router-outlet>
<odm-modals></odm-modals>
<odm-toasts aria-live="polite" aria-atomic="true"></odm-toasts>
