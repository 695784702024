import { AccountEffects } from './account/account.effects';
import { ChannelsEffects } from './channels/channels.effects';
import { DeletePendingSubscriptionsEffects } from './delete-pending-subscription/delete-pending-subscription.effects';
import { DeviceEffects } from './device/device.effects';
import { HeadTagsEffects } from './head-tags/head-tags.effects';
import { MarketingContentEffects } from './marketing-content/marketing-content.effects';
import { PromotionEffects } from './promotion/promotion.effects';
import { ResourcesEffects } from './resources/resources.effects';
import { StreamKeyEffects } from './stream-key/stream-key.effects';
import { SubscriberContentEffects } from './subscriber-content/subscriber-content.effects';
import { SubscriptionEmailEffects } from './subscription-email/subscription-email.effects';
import { ToastsEffects } from './toasts/toasts.effects';
import { UserEffects } from './user/user.effects';
import { VideoUserDataEffects } from './video-user-data/video-user-data.effects';
import { VideosEffects } from './videos/videos.effects';

export const effects = [
  AccountEffects,
  ChannelsEffects,
  DeletePendingSubscriptionsEffects,
  DeviceEffects,
  HeadTagsEffects,
  MarketingContentEffects,
  PromotionEffects,
  ResourcesEffects,
  StreamKeyEffects,
  SubscriberContentEffects,
  SubscriptionEmailEffects,
  ToastsEffects,
  UserEffects,
  VideoUserDataEffects,
  VideosEffects,
];
