<div class="page">
  <div class="container">
    <div class="text-center">
      <h1>Page not found</h1>
      <p>The page your are looking for could not be found.</p>
      <div>
        <a class="btn btn-primary" routerLink="/">Visit the Homepage</a>
        <a class="btn btn-outline-secondary" routerLink="/search">Search</a>
      </div>
    </div>
  </div><!-- end. container -->
</div><!-- end .page -->
