<div class="container">
  <div class="row">
    <div class="section-intro">
      <h2>{{ content.marketing_panel_headline }}</h2>
      <p>{{ content.marketing_panel_supporting_text }}</p>
      <div class="section-actions">
        <a class="btn btn-primary" routerLink="/user/subscribe">{{ customText.subscribeText }}</a>
      </div>
    </div>
    <div class="section-figure">
      <img class="img-fluid" [src]="content.marketing_panel_image.url">
    </div>
  </div>
  <hr>
  <odm-platforms></odm-platforms>
  <div>
    Available on these devices.
    <button class="btn btn-quiet" (click)="viewPlatforms.emit()">
      See all <svg-icon src="assets/icons/material-design-icons/keyboard_arrow_right/sharp.svg"></svg-icon>
    </button>
  </div>
</div>
