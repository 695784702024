<div [ngClass]="{'invisible-desktop hide-mobile' : visible === false}">
<div *ngIf="authenticated" class="jumbotron-body-actions-header">
  <h4 class="header-label">{{ headerLabel }}</h4>
  <p class="header-title">{{ headerTitle }}</p>
</div>
<div class="jumbotron-body-actions" [ngSwitch]="actionsType">

  <ng-container *ngSwitchCase="'marketing'">
      <a class="btn"
        [ngClass]="buttonPrimaryClass"
        [routerLink]="['/', { outlets: { primary: 'user/subscribe', channel: null } }]">
        {{ customText.subscribeText }}
      </a>
      <a class="btn"
        *ngIf="content.introduction_featured_trailer?.id as id"
        [ngClass]="buttonSecondaryClass"
        [routerLink]="['', { outlets: { video: [id] } }]">
        <svg-icon src="assets/icons/material-design-icons/play_circle_outline/outline.svg"></svg-icon>
        Watch Trailer
      </a>
  </ng-container>
  <ng-container *ngSwitchCase="'channel'">
    <a class="btn"
      [ngClass]="buttonPrimaryClass"
      *ngIf="content.intro_channel?.id as id"
      [routerLink]="['', { outlets: { channel: [id] } }]">
      <svg-icon src="assets/icons/custom-icons/carbon-playlist.svg"></svg-icon>
      Details
    </a>
    <a class="btn"
      [ngClass]="buttonSecondaryClass"
      *ngIf="content.intro_channel?.trailer_video?.id as id"
      [routerLink]="['', { outlets: { video: [id] } }]">
      Trailer
    </a>
  </ng-container>
  <ng-container *ngSwitchCase="'video'">
    <a class="btn"
      [ngClass]="buttonPrimaryClass"
      *ngIf="content.intro_video?.id as id"
      [routerLink]="['', { outlets: { video: [id] } }]">
      <svg-icon src="assets/icons/material-design-icons/play_circle_outline/outline.svg"></svg-icon>
      Play
    </a>
    <a class="btn"
      [ngClass]="buttonSecondaryClass"
      *ngIf="content.intro_channel?.id as id"
      [routerLink]="['', { outlets: { channel: [id] } }]">
      Details
    </a>
  </ng-container>
</div>
<span *ngIf="!authenticated" class="jumbotron-cta-detail">{{ customText.subscriptionText }}</span>
</div>
