<div class="container">
  <div class="section-intro">
    <h3>{{ title }}</h3>
    <p>{{ description }}</p>
  </div>
  <hr>
  <div class="tiles">
    <odm-video-item
      class="tile"
      *ngFor="let video of videos"
      [video]="video"
      [channelId]="channelId">
    </odm-video-item>
  </div>
  <div class="section-actions">
    <a class="btn btn-primary" routerLink="/channels">Browse All {{ customText.channelsTerm }}</a>
  </div>
</div>
