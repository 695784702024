<div class="carousel" tabindex="1" (keyup.arrowleft)="moveLeft()" (keyup.arrowright)="moveRight()">
  <div class="carousel-inner">
    <div class="tiles slide" [style.transform]="slideTransformation">
      <ng-content></ng-content>
    </div>
  </div>
  <button
    *ngIf="canMoveLeft || canMoveRight"
    class="btn btn-primary arrow left"
    [class.disabled]="!canMoveLeft"
    [attr.aria-disabled]="!canMoveLeft"
    aria-label="Previous"
    role="button"
    (click)="moveLeft()">
    <svg-icon src="assets/icons/material-design-icons/chevron_left/outline.svg"></svg-icon>
  </button>
  <button
    *ngIf="canMoveLeft || canMoveRight"
    class="btn btn-primary arrow right"
    [class.disabled]="!canMoveRight"
    [attr.aria-disabled]="!canMoveRight"
    aria-label="Next"
    role="button"
    (click)="moveRight()">
    <svg-icon src="assets/icons/material-design-icons/chevron_right/outline.svg"></svg-icon>
  </button>
</div>
