<div class="platforms-list">

  <ng-template [ngIf]="customText.appleStoreEnabled">
    <div class="platform">
      <a
        *ngIf="(customText.iosAppLive && customText.iphoneStoreLink) as storeLink else iphoneSoon"
        [href]="storeLink"
        target="_blank">
        <img src="assets/img/iphone.png" alt="iPhone">
      </a>
      <ng-template #iphoneSoon>
        <div class="tooltip-wrapper" aria-describedby="iphone-tooltip" tabindex="0">
          <img class="dim" src="assets/img/iphone.png" alt="iPhone">
          <span id="iphone-tooltip" role="tooltip">Coming Soon!</span>
        </div>
      </ng-template>
    </div>
    <div class="platform">
      <a
        *ngIf="(customText.iosAppLive && customText.ipadStoreLink) as storeLink else ipadSoon"
        [href]="storeLink"
        target="_blank">
        <img src="assets/img/ipad.png" alt="iPad">
      </a>
      <ng-template #ipadSoon>
        <div class="tooltip-wrapper" aria-describedby="ipad-tooltip" tabindex="0">
          <img class="dim" src="assets/img/ipad.png" alt="iPad">
          <span id="ipad-tooltip" role="tooltip">Coming Soon!</span>
        </div>
      </ng-template>
    </div>
    <div class="platform">
      <a
        *ngIf="(customText.tvosAppLive && customText.tvosStoreLink) as storeLink else tvosSoon"
        [href]="storeLink"
        target="_blank">
        <img src="assets/img/appletv.png" alt="Apple TV">
      </a>
      <ng-template #tvosSoon>
        <div class="tooltip-wrapper" aria-describedby="appletv-tooltip" tabindex="0">
          <img class="dim" src="assets/img/appletv.png" alt="Apple TV">
          <span id="appletv-tooltip" role="tooltip">Coming Soon!</span>
        </div>
      </ng-template>
    </div>
  </ng-template>

  <ng-template [ngIf]="customText.googlePlayEnabled">
    <div class="platform">
      <a
        *ngIf="(customText.androidAppLive && customText.androidStoreLink) as storeLink else androidSoon"
        [href]="storeLink"
        target="_blank">
        <img src="assets/img/android.png" alt="Android">
      </a>
      <ng-template #androidSoon>
        <div class="tooltip-wrapper" aria-describedby="android-tooltip" tabindex="0">
          <img class="dim" src="assets/img/android.png" alt="Android">
          <span id="android-tooltip" role="tooltip">Coming Soon!</span>
        </div>
      </ng-template>
    </div>
    <div class="platform">
      <a
        *ngIf="(customText.androidtvAppLive && customText.androidStoreLink) as storeLink else androidtvSoon"
        [href]="storeLink"
        target="_blank">
        <img src="assets/img/androidtv.png" alt="Android TV">
      </a>
      <ng-template #androidtvSoon>
        <div class="tooltip-wrapper" aria-describedby="androidtv-tooltip" tabindex="0">
          <img class="dim" src="assets/img/androidtv.png" alt="Android TV">
          <span id="androidtv-tooltip" role="tooltip">Coming Soon!</span>
        </div>
      </ng-template>
    </div>
  </ng-template>

  <ng-template [ngIf]="customText.rokuEnabled">
    <div class="platform">
      <a
        *ngIf="(customText.rokuAppLive && customText.rokuStoreLink) as storeLink else rokuSoon"
        [href]="storeLink"
        target="_blank">
        <img src="assets/img/roku.png" alt="Roku">
      </a>
      <ng-template #rokuSoon>
        <div class="tooltip-wrapper" aria-describedby="roku-tooltip" tabindex="0">
          <img class="dim" src="assets/img/roku.png" alt="Roku">
          <span id="roku-tooltip" role="tooltip">Coming Soon!</span>
        </div>
      </ng-template>
    </div>
  </ng-template>

  <ng-template [ngIf]="customText.amazonEnabled">
    <div class="platform">
      <a
        *ngIf="(customText.firetvAppLive && customText.amazonStoreLink) as storeLink else amazonSoon"
        [href]="storeLink"
        target="_blank">
        <img class="amazon" src="assets/img/amazonfiretv.png" alt="FireTV">
      </a>
      <ng-template #amazonSoon>
        <div class="tooltip-wrapper" aria-describedby="amazon-tooltip" tabindex="0">
          <img class="dim amazon" src="assets/img/amazonfiretv.png" alt="FireTV">
          <span id="amazon-tooltip" role="tooltip">Coming Soon!</span>
        </div>
      </ng-template>
    </div>
  </ng-template>
</div>
