<div class="container">
  <div class="row">
    <div class="section-intro">
      <h2>{{ content.announcement_headline }}</h2>
      <p>{{ content.announcement_supporting_text }}</p>
    </div>
    <div class="section-figure">
      <img class="img-fluid" [src]="content.announcement_image.url">
    </div>
  </div>
  <hr>
  <odm-platforms></odm-platforms>
  <div>
    Available on these devices.
    <button class="btn btn-quiet" (click)="viewPlatforms.emit()">
      See all <svg-icon src="assets/icons/material-design-icons/keyboard_arrow_right/sharp.svg"></svg-icon>
    </button>
  </div>
</div>
