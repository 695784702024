<ng-container *ngIf="promotion$ | async as promotion">
  <div
    class="promotion"
    role="alert"
    [style.background]="'#' + promotion.color"
    @fade>
    {{ promotion.message }}
    <button class="close" data-dismiss="alert" aria-label="Close" (click)="clearPromotion()">
      <svg-icon src="assets/icons/material-design-icons/close/sharp.svg"></svg-icon>
    </button>
  </div>
</ng-container>
