<div class="modal-backdrop show" @fadeIn></div>
<div class="modal d-block" tabindex="-1" role="dialog">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h2 class="modal-title mx-auto">Help &amp; Support</h2>
        <button class="close" aria-label="Close" (click)="close()">
          <svg-icon src="assets/icons/material-design-icons/close/sharp.svg"></svg-icon>
        </button>
      </div>
      <div class="modal-body text-center">
        <ng-container *ngIf="account$ | async as account">
          <div class="pt-3" *ngIf="account.support_email as email">
            <p>Content questions and feedback?</p>
            <a class="btn btn-outline-secondary"  [href]="'mailto:' + email">
              <svg-icon src="assets/icons/material-design-icons/email/outline.svg"></svg-icon>
              Contact {{ account.name }}
            </a>
          </div>
          <div class="pt-3" *ngIf="account.technical_support_email as email">
            <p>Need help?</p>
            <a class="btn btn-outline-secondary" [href]="'mailto:' + email">
              <svg-icon src="assets/icons/material-design-icons/contact_support/outline.svg"></svg-icon>
              Contact Technical Support
            </a>
          </div>
        </ng-container>
      </div><!-- end .modal-body -->
    </div>
  </div>
</div>



