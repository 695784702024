<ng-container *ngIf="toasts$ | async as toasts">
  <div class="toast show" role="alert" aria-live="assertive" aria-atomic="true" *ngFor="let toast of toasts" @slideRight>
    <div class="toast-header">
      <svg-icon *ngIf="toast.icon" [src]="toast.icon"></svg-icon>
      {{ toast.title }}
      <button
        type="button"
        class="close"
        data-dismiss="toast"
        aria-label="Close"
        (click)="hideToast(toast.id)">
        <svg-icon src="assets/icons/material-design-icons/close/sharp.svg"></svg-icon>
      </button>
    </div>
    <div class="toast-body" [innerHTML]="trustHtml(toast.message)"></div>
  </div>
</ng-container>

