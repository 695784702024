<div class="modal-backdrop show" @fadeIn></div>
<div class="modal d-block" tabindex="-1" role="dialog">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header" [class.text-center]="tokenLoaded$ | async">
        <ng-container *ngIf="tokenLoaded$ | async; else noTokenHead">
          <ng-container *ngIf="method$ | async as method">
            <h2 class="modal-title" style="font-size: 24px;">Please check your {{ method === 'phone' ? 'text messages' : 'email' }} to confirm your sign in.</h2>
          </ng-container>
        </ng-container>
        <ng-template #noTokenHead>
          <h2 class="modal-title">Sign in</h2>
        </ng-template>
        <button class="close" aria-label="Close" (click)="close()">
          <svg-icon src="assets/icons/material-design-icons/close/sharp.svg"></svg-icon>
        </button>
      </div>
      <div class="modal-body" [class.text-center]="tokenLoaded$ | async">
        <ng-container *ngIf="tokenLoaded$ | async; else noToken">
          <p class="text-left"><em>If you regularly use this computer, we suggest you do not ever sign out.</em></p>
          <!-- resend email -->
          <div class="alert alert-danger" role="alert" *ngIf="resendEmailError$ | async as error" [innerHTML]="error.error?.message || 'Resend Email Error'">
          </div>
          <ng-container *ngIf="resendEmailLoading$ | async | yesNo as loading">
            <button
              class="btn btn-outline-secondary"
              [disabled]="loading === 'yes'"
              (click)="resendEmail()">
              <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" *ngIf="loading === 'yes'"></span>
              <ng-container *ngIf="method$ | async as method">
                {{  method === 'phone' ? 'Send Email Sign In' : 'Resend Email' }}
              </ng-container>
            </button>
          </ng-container>
          <!-- check auth -->
          <ng-container *ngIf="userLoading$ | async | yesNo as loading">
            <button
              class="btn btn-primary"
              [disabled]="loading === 'yes'"
              (click)="checkAuth()">
              <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" *ngIf="loading === 'yes'"></span>
              Refresh
            </button>
          </ng-container>
        </ng-container>
        <ng-template #noToken>
          <p>To sign in, enter the email address associated with your account. You will be sent a verification email containing a link to unlock the content.</p>
          <ng-container *ngIf="tokenError$ | async as error">
            <div class="alert alert-danger" role="alert" *ngIf="error" [innerHTML]="error.error?.message || 'Sign In Error'"></div>
            <ng-container *ngIf="error.error?.message?.includes('re-send your activation email')">
              <div class="alert alert-danger" role="alert" *ngIf="resendSubscriptionEmailError$ | async as error" [innerHTML]="error.error?.message || 'Resend Email Error'"></div>
              <ng-container *ngIf="resendSubscriptionEmailLoading$ | async | yesNo as loading">
                <button
                  class="btn btn-primary btn-block"
                  [disabled]="loading === 'yes'"
                  (click)="resendSubscriptionEmail()">
                  <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" *ngIf="loading === 'yes'"></span>
                  Resend Activation Email
                </button>
              </ng-container>
            </ng-container>
          </ng-container>
          <form [formGroup]="userForm" (ngSubmit)="onSubmit()">
            <div class="form-group">
              <label for="email">Email</label>
              <input type="email" class="form-control" id="email" placeholder="Enter email" formControlName="email">
            </div>
            <ng-container *ngIf="tokenLoading$ | async | yesNo as loading">
              <button
                type="submit"
                class="btn btn-primary btn-block"
                [disabled]="!userForm.valid || loading === 'yes'">
                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" *ngIf="loading === 'yes'"></span>
                Send Sign In Verification Email
              </button>
            </ng-container>
          </form>
          <p class="mt-4 text-right">
            Don't have an account?
            <a routerLink="/user/subscribe" (click)="close()">Start your free trial</a>
          </p>
        </ng-template>
      </div>
    </div>
  </div>
</div>
