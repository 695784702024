<div class="container">
  <div class="section-intro">
    <h3>{{ content.devices_headline }}</h3>
    <p>{{ content.devices_supporting_text }}</p>
  </div>
  <div class="device-demo" aria-hidden="true">
    <div class="device-tv">
      <div class="device-screen" [ngStyle]="{ 'background-image': 'url(' + content.devices_image.url + ')' }"></div>
    </div>
    <div class="device-laptop">
      <div class="device-screen" [ngStyle]="{ 'background-image': 'url(' + content.devices_image.url + ')' }"></div>
    </div>
    <div class="device-tablet">
      <div class="device-screen" [ngStyle]="{ 'background-image': 'url(' + content.devices_image.url + ')' }"></div>
    </div>
    <div class="device-phone">
      <div class="device-screen" [ngStyle]="{ 'background-image': 'url(' + content.devices_image.url + ')' }"></div>
    </div>
  </div>
  <odm-platforms></odm-platforms>
</div>
