import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ElementRef,
  ViewChild,
  Renderer2,
  HostBinding,
  HostListener
} from '@angular/core';

import { Observable, merge } from 'rxjs';
import { filter, map, switchMap} from 'rxjs/operators';

import { Store, select } from '@ngrx/store';

import * as fromRoot from '../../../store';
import * as fromCore from '../../../core/store';
import { ScrollService } from '../../services/scroll/scroll.service';
import { Router, Scroll, Event } from '@angular/router';
import { unwrap } from 'src/app/util/code.util';

@Component({
  selector: 'odm-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NavComponent implements OnInit {
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    // Hide mobile menu on large screens
    if (window.innerWidth > 991) {
      this.showMenuPane = false;
    }
  }

  @HostBinding('class.is-overlapping') isOverlapping: boolean = false;
  @ViewChild('siteHeader', { static: true }) siteHeader: ElementRef;

  account$: Observable<any>;
  authenticated$: Observable<boolean>;
  content$: Observable<any>;
  loading$: Observable<boolean>;
  routeData$: Observable<any>;
  authLoaded$: Observable<boolean>;
  navAtTop$: Observable<boolean>;
  showMenuPane = false;

  constructor(
    private hostElement: ElementRef,
    private renderer: Renderer2,
    private router: Router,
    private store: Store<any>,
    private scrollService: ScrollService
  ) {}

  ngOnInit() {
    this.account$ = this.store.pipe(select(fromCore.selectAccount));
    this.authenticated$ = this.store.pipe(
      select(fromCore.selectDeviceAuthenticated)
    );
    this.content$ = this.authenticated$.pipe(
      switchMap(authenticated => this.store.pipe(select(
        authenticated ? fromCore.selectSubscriberContent : fromCore.selectMarketingContent
      )))
    );
    this.loading$ = this.store.pipe(select(fromCore.selectLoading));
    this.routeData$ = this.store.pipe(select(fromRoot.selectRouteData));
    this.authLoaded$ = this.store.pipe(select(fromCore.selectUserAuthLoaded));

    this.navAtTop$ = merge(
      this.scrollService.scroll$.pipe(
        map(() => this.hostElement.nativeElement.getBoundingClientRect().top < 0)
      ),
      this.router.events.pipe(
        filter(e => e instanceof Scroll),
        map((e: Scroll) => e.position && e.position[1] > 0)
      )
    );



    this.routeData$.subscribe(data => {
      this.isOverlapping = unwrap(data, 'navOverlap') === true;
    });

    // Adjust layout of header as needed
    this.navAtTop$.subscribe(atTop => {
      this.renderer.setStyle(
        this.hostElement.nativeElement,
        'height',
        atTop ? this.siteHeader.nativeElement.offsetHeight + 'px' : 'auto'
      );
    });
  }

  openAuthModal() {
    this.store.dispatch(fromCore.openModal({ key: 'auth' }));
  }
}
