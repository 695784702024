<div class="action-ribbon">
  <div class="container">
    <div class="action-ribbon-intro">
      <h3>Install on your device</h3>
      <p>Enter your mobile number to install the app on your phone.</p>
    </div>
    <div class="action-ribbon-form">
      <div class="alert alert-success" role="alert" *ngIf="loaded else notLoaded">
        A link has been sent to {{ phoneForm.value.phone }}.
      </div>
      <ng-template #notLoaded>
        <form class="form-inline" [formGroup]="phoneForm" (ngSubmit)="onSubmit()">
          <label class="sr-only" for="phone">Phone Number</label>
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text">
                <svg-icon src="assets/icons/material-design-icons/phone_iphone/outline.svg"></svg-icon>
              </span>
            </div>
            <odm-intl-tel-input [parentForm]="phoneForm"></odm-intl-tel-input>
            <div class="input-group-append" *ngIf="error">
              <div class="alert alert-danger" role="alert" [innerHTML]="error.error?.message || 'Error'"></div>
            </div>
          </div>
          <button
            type="submit"
            class="btn btn-outline-light"
            [disabled]="!phoneForm.valid || loading">
            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" *ngIf="loading"></span>
            Text Me A Download Link
          </button>
        </form>
      </ng-template>
    </div>
  </div>
</div>
